<template>
  <div class="container_con" style="background: none;">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px">
      <el-card style="margin-bottom: 15px;">
        <div class="con_title"><span></span> 验收</div>
        <el-form-item label="单据编号前缀" prop="no">
          <el-select v-model="form.no" placeholder="请选择" style="width: 300px; margin-right: 15px;" filterable>
            <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="验收日期：" prop="purchase_acceptance_date">
          <el-date-picker v-model="form.purchase_acceptance_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
            style="width:300px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="验收员:" prop="admin_id">
          <el-select v-model="form.admin_id" placeholder="请选择" style="width:300px" filterable>
            <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code" v-for="(item, index) in rylist" :key="index"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" style="width:300px" />
        </el-form-item>
      </el-card>
      <el-card style="margin-bottom: 15px;" v-if="data">
        <div class="con_title"><span></span> 采购计划</div>
        <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="6">开票日期：{{ data.purchase_plan_date }}</el-col>
            <el-col :span="6">联系电话：{{ data.merchant.mebl_operation_mode }}</el-col>
            <el-col :span="6">部门：{{ data.department_name }}</el-col>
            <el-col :span="6">供货单位：{{ data.merchant.merchant_name }}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">单据编号：{{ data.purchase_plan_no }}</el-col>
            <el-col :span="6">单位编号：{{ data.merchant.merchant_no }}</el-col>
            <el-col :span="6">采购员：{{ data.admin_name }}</el-col>
          </el-row>
        </div>
        <div class="" style="margin-top: 10px; font-size: 14px;">
          <div style="padding-bottom: 20px;">采购产品：</div>
          <div class="">
            <el-table :data="pros" style="width: 100%" ref="tableDataRefs" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" :selectable="selectable_func" />
              <el-table-column label="产品编号" sortable :sort-method="formatterproduct_no" width="120">
                <template v-slot="scope">
                  {{ scope.row.product.product_no }}
                </template>
              </el-table-column>
              <el-table-column label="品名" width="180" sortable :sort-method="formatterproduct_name">
                <template v-slot="scope">
                  {{ scope.row.product.product_name }}
                </template>
              </el-table-column>
              <el-table-column label="型号规格">
                <template v-slot="scope">
                  {{ scope.row.product.model }}
                </template>
              </el-table-column>
              <el-table-column label="生产厂家" width="150">
                <template v-slot="scope">
                  {{ scope.row.product.manufacturer }}
                </template>
              </el-table-column>
              <el-table-column label="包装单位">
                <template v-slot="scope">
                  {{ scope.row.pack_unit_name }}
                </template>
              </el-table-column>
              <el-table-column label="总数量" sortable :sort-method="formatterproduct_num">
                <template v-slot="scope">
                  {{ scope.row.product_num }}
                </template>
              </el-table-column>
              <el-table-column label="已验收数量">
                <template v-slot="scope">
                  {{ scope.row.acceptance_num }}
                </template>
              </el-table-column>
              <el-table-column label="剩余验收数量" width="150" sortable :sort-method="formattersurplus_acceptance_num">
                <template v-slot="scope">
                  <div>
                    <el-input-number v-model="scope.row.surplus_acceptance_num" controls-position="right" :min="0"
                      size="small"></el-input-number>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="150" sortable :sort-method="formatterproduct_price">
                <template v-slot="scope">
                  <div>
                    <el-input-number v-model="scope.row.product_price" controls-position="right" :min="0"
                      size="small"></el-input-number>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="金额" sortable :sort-method="formattertotal" width="150">
                <template v-slot="scope">
                  {{ (parseFloat(scope.row.surplus_acceptance_num) * parseFloat(scope.row.product_price) || 0).toFixed(4) }}
                </template>
              </el-table-column>
              <el-table-column label="产品批号" width="150">
                <template v-slot="scope">
                  <div>
                    <el-input v-model="scope.row.batch_number" placeholder="请输入产品批号" style="width:100%" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="有效期至" width="170">
                <template v-slot="scope">
                  <div>
                    <el-date-picker v-model="scope.row.valid_until" type="date" value-format="YYYY-MM-DD"
                      placeholder="选择日期(长期不选)" style="width:100%" :shortcuts="shortcuts">
                    </el-date-picker>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="验收结论" width="150">
                <template v-slot="scope">
                  <div>
                    <el-select v-model="scope.row.acceptance_conclusion" placeholder="请选择" style="width:100%">
                      <el-option :label="item2.title" v-for="(item2, index2) in ysjllist" :key="index2"
                        :value="item2.title"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="质量情况" width="150">
                <template v-slot="scope">
                  <div>
                    <el-select v-model="scope.row.quality_situation" placeholder="请选择" style="width:100%">
                      <el-option :label="item2.title" v-for="(item2, index2) in zlqklist" :key="index2"
                        :value="item2.title"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="批号生产日期">
                    <template v-slot="scope">
                      {{scope.row.batch_number}}
                    </template>
                </el-table-column>
                <el-table-column label="有效期至">
                    <template v-slot="scope">
                      {{scope.row.valid_until || '长效期'}}
                    </template>
                </el-table-column> -->
              <el-table-column label="注册证名">
                <template v-slot="scope">
                  {{ scope.row.product.register_cert_name }}
                </template>
              </el-table-column>
              <el-table-column label="注册证号">
                <template v-slot="scope">
                  {{ scope.row.product.register_cert_no }}
                </template>
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="100">
                <template v-slot="scope">
                  <el-button type="text" size="small" @click="copy(scope.row, scope.$index)">复制</el-button>
                  <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
      <!-- <el-card style="margin-bottom: 15px;" v-if="ids.length>0">
        <div v-for="(item,index) in pros" :key="index" >
            <template v-if="ids.indexOf(item.purchase_plan_product_id)!=-1">
                <div class="con_title" ><span></span> {{item.title}}---验收信息 </div>
                <el-form-item label="验收数量：" prop="product_num">
                    <el-input-number v-model="item.product_num" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="产品批号：" prop="batch_number">
                    <el-input v-model="item.batch_number" placeholder="请输入产品批号" style="width:300px" />
                </el-form-item>
                <el-form-item label="有效期至：" prop="valid_until">
                  <el-date-picker
                    v-model="item.valid_until"
                    type="date" value-format="YYYY-MM-DD"
                    placeholder="选择日期(长期不选)"
                    style="width:300px">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="验收结论：" prop="acceptance_conclusion">
                    <el-select v-model="item.acceptance_conclusion" placeholder="请选择"
                        style="width:300px" >
                        <el-option :label="item2.title" v-for="(item2,index2) in ysjllist"
                            :key="index2" :value="item2.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="质量情况：" prop="quality_situation">
                    <el-select v-model="item.quality_situation" placeholder="请选择"
                        style="width:300px" >
                        <el-option :label="item2.title" v-for="(item2,index2) in zlqklist"
                            :key="index2" :value="item2.title"></el-option>
                    </el-select>
                </el-form-item>
            </template>
        </div>
    </el-card> -->
    </el-form>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addpurchaseAccept",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
        no: ''
      },
      rules: {
        purchase_acceptance_date: [
          { required: true, message: "验收日期不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "验收员不能为空", trigger: "blur" }
        ],

      },
      zjlist: [],
      rylist: [],

      list: '',
      data: '',


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ysjllist: [{ title: '验收合格' }, { title: '验收不合格' }],
      zlqklist: [{ title: '质量合格' }, { title: '质量不合格' }],
      pros: [],
      shortcuts: [{
        text: '2099-12-31',
        value: '2099-12-31'
      }, {
        text: '长效期',
        value: 'null'
      }],
      nrd_id: 0
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    this.get_data();
    this.form.purchase_acceptance_date = this.today_val()
    this.getOptions()
  },
  methods: {
    formatterproduct_no(a, b) {
      return a.product.product_no .localeCompare(b.product.product_no )
    },
    formatterproduct_name(a, b) {
      return a.product.product_name.localeCompare(b.product.product_name)
    },
    formatterproduct_num(a, b) {
      return Number(a.product_num) - Number(b.product_num);
    },
    formattersurplus_acceptance_num(a, b) {
      return Number(a.surplus_acceptance_num) - Number(b.surplus_acceptance_num);
    },
    formatterproduct_price(a, b) {
      return Number(a.product_price) - Number(b.product_price);
    },
    formattertotal(a,b){
      return Number((parseFloat(a.surplus_acceptance_num) * parseFloat(a.product_price) || 0).toFixed(4)) - Number((parseFloat(b.surplus_acceptance_num) * parseFloat(b.product_price) || 0).toFixed(4));
    },
    handleDelete(index) {
      const _this = this;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.pros.splice(index, 1)
      })
    },
    copy(row, index) {
      let new_row = { ...row };
      new_row.rdid = this.pros.length;
      this.nrd_id + 1;
      this.pros.splice(index + 1, 0, new_row)
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.rdid);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
      console.log(this.ids)
    },
    /** 提交按钮 */
    submitForm: function (form) {
      if (this.ids.length == 0) {
        this.$message.error('请选择采购计划产品');
        return false;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {

          if (form == 'form') {
            let _data = { ...this.form };
            _data.purchase_plan_id = this.id;
            let arr = [];
            for (let i = 0; i < this.pros.length; i++) {
              if (this.ids.indexOf(this.pros[i].rdid) != -1) {
                console.log(this.pros[i].surplus_acceptance_num)
                if (!this.pros[i].surplus_acceptance_num || !this.pros[i].batch_number) {
                  this.$message.error('请完善产品批号、数量');
                  return false
                }
                arr.push({
                  purchase_plan_product_id: this.pros[i].id,
                  valid_until: this.pros[i].valid_until == 'Invalid Date' ? null : (this.pros[i].valid_until || null),
                  batch_number: this.pros[i].batch_number,
                  acceptance_conclusion: this.pros[i].acceptance_conclusion,
                  quality_situation: this.pros[i].quality_situation,
                  product_num: this.pros[i].surplus_acceptance_num,
                  product_price: this.pros[i].product_price,
                  total_num: this.pros[i].product_num,
                  acceptance_num: this.pros[i].acceptance_num,
                  amount: (parseFloat(this.pros[i].product_price) * parseFloat(this.pros[i].surplus_acceptance_num) || 0),
                })
              }
            }

            this.is_ajax = true;
            _data.product_array = JSON.stringify(arr);
            this.$httpPost("/backend/PurchaseAcceptance/save", _data).then((res) => {
              if (res.status == 200) {
                this.$message.success('新增成功');

                this.$router.go(-1)
              } else {
                this.$message.error(res.message);
              }
              this.is_ajax = false;
            }).catch((err) => {
              console.log(err);
              this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data() {
      this.$httpGet("/backend/PurchasePlan/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          this.data = res.data;
          let pros = res.data.purchase_plan_product;
          let new_pros = [];
          for (let i = 0; i < pros.length; i++) {
            pros[i].acceptance_conclusion = '验收合格';
            pros[i].quality_situation = '质量合格';
            pros[i].product_price = parseFloat(pros[i].product_price);
            pros[i].rdid = i;
            this.nrd_id = i;
            // pros.push({
            //     purchase_plan_product_id:pros[i].id,
            //     valid_until:null,
            //     batch_number:'',
            //     title:pros[i].product.product_name,
            //     acceptance_conclusion:'验收合格',
            //     quality_situation:'质量合格',
            //     product_num:1
            // })
            if (pros[i].surplus_acceptance_num != 0) {
              new_pros.push(pros[i])
            }
          }
          this.pros = new_pros;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '采购验收'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (this.zjlist.length > 0) {
            this.form.no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      this.$httpGet("/backend/sys.Admin/acceptanceList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data.data;
          // 默认选中第一个
          // if(this.rylist.length>0){
          //     this.form.admin_id=this.rylist[0].id
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}

//  /deep/ .el-table__header-wrapper .el-checkbox {
//   display: none;
// }
</style>
